.donut-chart {
  background-color: #e6e6e6;
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto 2rem;
  border-radius: 100%;
}
.pie {
  transition: all 0.5s;
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 100%;
  clip: rect(0px, 400px, 400px, 200px);
  transform:rotate(45deg);
}
p.center {
  background: #ffffff;
  position: absolute;
  text-align: center;
  font-size: 28px;
  top:0;left:0;bottom:0;right:0;
  width: 180px;
  height: 180px;
  margin: auto;
  border-radius: 50%;
  line-height: 35px;
  padding: 15% 0 0;
}

.hold {
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 100%;
  clip: rect(265px, 200px, 400px, 60px);
}

#pie-slice-1 .pie {
  background-color: #e6e6e6;
}
#pie-slice-1 .pie.active {
  background-color: #2EC4E0;
}
#pie-slice-2 .pie {
  background-color: #e6e6e6;
}
#pie-slice-2 .pie.active {
  background-color: #6EBF66;
}
#pie-slice-3 .pie {
  background-color: #e6e6e6;
}
#pie-slice-3 .pie.active {
  background-color: #0878A6;
}
#pie-slice-4 .pie {
  background-color: #e6e6e6;
}
#pie-slice-4 .pie.active {
  background-color: #F03D57;
}
#pie-slice-5 .pie {
  background-color: #e6e6e6;
}
#pie-slice-5 .pie.active {
  background-color: #DBAC66;
}
#pie-slice-6 .pie {
  background-color: #e6e6e6;
}
#pie-slice-6 .pie.active {
  background-color: #F27D57;
}
#pie-slice-7 .pie {
  background-color: #e6e6e6;
}
#pie-slice-7 .pie.active {
  background-color: #52875C;
}
#pie-slice-8 .pie {
  background-color: #e6e6e6;
}
#pie-slice-8 .pie.active {
  background-color: #FCC759;
}
#pie-slice-2 {
  transform: rotate(45deg);
}
#pie-slice-3 {
  transform: rotate(90deg);
}
#pie-slice-4 {
  transform: rotate(135deg);
}
#pie-slice-5 {
  transform: rotate(180deg);
}
#pie-slice-6 {
  transform: rotate(225deg);
}
#pie-slice-7 {
  transform: rotate(270deg);
}
#pie-slice-8 {
  transform: rotate(315deg);
}


#pie-slice-1 .pie:hover{
  background-color: #2EC4E0;
}
#pie-slice-2 .pie:hover{
  background-color: #6EBF66;
}
#pie-slice-3 .pie:hover{
  background-color: #0878A6;
}
#pie-slice-4 .pie:hover{
  background-color: #F03D57;
}
#pie-slice-5 .pie:hover{
  background-color: #DBAC66;
}
#pie-slice-6 .pie:hover{
  background-color: #F27D57;
}
#pie-slice-7 .pie:hover{
  background-color: #52875C
}
#pie-slice-8 .pie:hover{
  background-color: #FCC759;
}


.nonagon-chart {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto 2rem;
  border-radius: 100%;
}
.nonagon-slice {
  position: absolute;
  /* padding-top: 1em; */
  /* left: 8em; */
  /* width: 7.25em;
  height: 19em;
  clip: rect(0, 20em, 10em, 0); */
  left: 120px;
  width: 140px;
  height: 380px;
  clip: rect(0, 200px, 100px, 0);
  text-align: center;
}

.nonagon-slice-1 .nonagon-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon-slice-1 .nonagon-slice-triangle.active {
  background-color: #BEFACD;
}
.nonagon-slice-2 .nonagon-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon-slice-2 .nonagon-slice-triangle.active {
  background-color: #387A49;
}
.nonagon-slice-3 .nonagon-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon-slice-3 .nonagon-slice-triangle.active {
  background-color: #97C7A3;
}
.nonagon-slice-4 .nonagon-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon-slice-4 .nonagon-slice-triangle.active {
  background-color: #5BC776;
}
.nonagon-slice-5 .nonagon-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon-slice-5 .nonagon-slice-triangle.active {
  background-color: #21472A;
}
.nonagon-slice-6 .nonagon-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon-slice-6 .nonagon-slice-triangle.active {
  background-color: #6E9176;
}
.nonagon-slice-7 .nonagon-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon-slice-7 .nonagon-slice-triangle.active {
  background-color: #67E086;
}
.nonagon-slice-8 .nonagon-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon-slice-8 .nonagon-slice-triangle.active {
  background-color: #5D7A64
}
.nonagon-slice-9 .nonagon-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon-slice-9 .nonagon-slice-triangle.active {
  background-color: #73FB95;
}


.nonagon-slice-1 {
  transform: rotate(0deg);
}
.nonagon-slice-2 {
  transform: rotate(40deg);
}
.nonagon-slice-3 {
  transform: rotate(80deg);
}
.nonagon-slice-4 {
  transform: rotate(120deg);
}
.nonagon-slice-5 {
  transform: rotate(160deg);
}
.nonagon-slice-6 {
  transform: rotate(200deg);
}
.nonagon-slice-7 {
  transform: rotate(240deg);
}
.nonagon-slice-8 {
  transform: rotate(280deg);
}
.nonagon-slice-9 {
  transform: rotate(320deg);
}
.nonagon-slice-triangle {
  transition: all 0.5s;
  position: absolute;
  width: 140px;
  height: 140px;
  clip-path: polygon(0% 0%, 50% 135%, 100% 0%);
  z-index: 999;
}

.nonagon-slice-1 .nonagon-slice-triangle:hover {
  background-color: #BEFACD;
}
.nonagon-slice-2 .nonagon-slice-triangle:hover {
  background-color: #387A49;
}
.nonagon-slice-3 .nonagon-slice-triangle:hover {
  background-color: #97C7A3;
}
.nonagon-slice-4 .nonagon-slice-triangle:hover {
  background-color: #5BC776;
}
.nonagon-slice-5 .nonagon-slice-triangle:hover {
  background-color: #21472A;
}
.nonagon-slice-6 .nonagon-slice-triangle:hover {
  background-color: #6E9176;
}
.nonagon-slice-7 .nonagon-slice-triangle:hover {
  background-color: #67E086;
}
.nonagon-slice-8 .nonagon-slice-triangle:hover{
  background-color: #5D7A64
}
.nonagon-slice-9 .nonagon-slice-triangle:hover{
  background-color: #73FB95;
}


.star-chart {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto 2rem;
  border-radius: 100%;
}
.star-slice {
  position: absolute;
  left: 120px;
  width: 140px;
  height: 444px;
  clip-path: polygon(0 50%, 50% 0, 100% 50%);
  text-align: center;
}

.star-slice-1 .star-slice-triangle {
  background-color: #e6e6e6;
}
.star-slice-1 .star-slice-triangle.active {
  background-color: #EBBA38;
}
.star-slice-2 .star-slice-triangle {
  background-color: #e6e6e6;
}
.star-slice-2 .star-slice-triangle.active {
  background-color: #6B5E3A;
}
.star-slice-3 .star-slice-triangle {
  background-color: #e6e6e6;
}
.star-slice-3 .star-slice-triangle.active {
  background-color: #B8922C;
}
.star-slice-4 .star-slice-triangle {
  background-color: #e6e6e6;
}
.star-slice-4 .star-slice-triangle.active {
  background-color: #F6942F;
}
.star-slice-5 .star-slice-triangle {
  background-color: #e6e6e6;
}
.star-slice-5 .star-slice-triangle.active {
  background-color: #6B551A;
}
.star-slice-6 .star-slice-triangle {
  background-color: #e6e6e6;
}
.star-slice-6 .star-slice-triangle.active {
  background-color: #EED181;
}
.star-slice-1 {
  transform: rotate(0deg);
}
.star-slice-2 {
  transform: rotate(60deg);
}
.star-slice-3 {
  transform: rotate(120deg);
}
.star-slice-4 {
  transform: rotate(180deg);
}
.star-slice-5 {
  transform: rotate(240deg);
}
.star-slice-6 {
  transform: rotate(300deg);
}
.star-slice-triangle {
  transition: all 0.5s;
  position: absolute;
  width: 140px;
  height: 380px;
  clip-path: polygon(0 38%, 50% 0, 100% 38%);
  z-index: 999;
}

.star-slice-1 .star-slice-triangle:hover {
  background-color: #EBBA38;
}
.star-slice-2 .star-slice-triangle:hover {
  background-color: #6B5E3A;
}
.star-slice-3 .star-slice-triangle:hover {
  background-color: #B8922C;
}
.star-slice-4 .star-slice-triangle:hover {
  background-color: #F6942F;
}
.star-slice-5 .star-slice-triangle:hover {
  background-color: #6B551A;
}
.star-slice-6 .star-slice-triangle:hover {
  background-color: #EED181;
}

.pentagon-chart {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto 2rem;
  border-radius: 100%;
}
.pentagon-slice {
  position: absolute;
  left: 120px;
  width: 195px;
  height: 280px;
  clip: rect(0, 200px, 100px, 0);
  text-align: center;
}

.pentagon-slice-1 .pentagon-slice-triangle {
  background-color: #e6e6e6;
}
.pentagon-slice-1 .pentagon-slice-triangle.active {
  background-color: #51BADB;
}
.pentagon-slice-2 .pentagon-slice-triangle {
  background-color: #e6e6e6;
}
.pentagon-slice-2 .pentagon-slice-triangle.active {
  background-color: #3E555C;
}
.pentagon-slice-3 .pentagon-slice-triangle {
  background-color: #e6e6e6;
}
.pentagon-slice-3 .pentagon-slice-triangle.active {
  background-color: #3E90A8;
}
.pentagon-slice-4 .pentagon-slice-triangle {
  background-color: #e6e6e6;
}
.pentagon-slice-4 .pentagon-slice-triangle.active {
  background-color: #224E5C;
}
.pentagon-slice-5 .pentagon-slice-triangle {
  background-color: #e6e6e6;
}
.pentagon-slice-5 .pentagon-slice-triangle.active {
  background-color: #97D0E1;
}

.pentagon-slice-1 {
  transform: rotate(0deg);
}
.pentagon-slice-2 {
  transform: rotate(72deg);
}
.pentagon-slice-3 {
  transform: rotate(144deg);
}
.pentagon-slice-4 {
  transform: rotate(216deg);
}
.pentagon-slice-5 {
  transform: rotate(288deg);
}
.pentagon-slice-triangle {
  transition: all 0.5s;
  position: absolute;
  width: 200px;
  height: 100px;
  clip-path: polygon(0% 0%, 50% 135%, 100% 0%);
  z-index: 999;
}

.pentagon-slice-1 .pentagon-slice-triangle:hover {
  background-color: #51BADB;
}
.pentagon-slice-2 .pentagon-slice-triangle:hover {
  background-color: #3E555C;
}
.pentagon-slice-3 .pentagon-slice-triangle:hover {
  background-color: #3E90A8;
}
.pentagon-slice-4 .pentagon-slice-triangle:hover {
  background-color: #224E5C;
}
.pentagon-slice-5 .pentagon-slice-triangle:hover {
  background-color: #97D0E1;
}


.nonagon2-chart {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto 2rem;
  border-radius: 100%;
}
.nonagon2-slice {
  position: absolute;
  /* padding-top: 1em; */
  /* left: 8em; */
  /* width: 7.25em;
  height: 19em;
  clip: rect(0, 20em, 10em, 0); */
  left: 120px;
  width: 140px;
  height: 380px;
  clip: rect(0, 200px, 100px, 0);
  text-align: center;
}

.nonagon2-slice-1 .nonagon2-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon2-slice-1 .nonagon2-slice-triangle.active {
  background-color: #D64280;
}
.nonagon2-slice-2 .nonagon2-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon2-slice-2 .nonagon2-slice-triangle.active {
  background-color: #573543;
}
.nonagon2-slice-3 .nonagon2-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon2-slice-3 .nonagon2-slice-triangle.active {
  background-color: #FEA8CD;
}
.nonagon2-slice-4 .nonagon2-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon2-slice-4 .nonagon2-slice-triangle.active {
  background-color: #AB6884;
}
.nonagon2-slice-5 .nonagon2-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon2-slice-5 .nonagon2-slice-triangle.active {
  background-color: #F55698;
}
.nonagon2-slice-6 .nonagon2-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon2-slice-6 .nonagon2-slice-triangle.active {
  background-color: #95003F;
}
.nonagon2-slice-7 .nonagon2-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon2-slice-7 .nonagon2-slice-triangle.active {
  background-color: #BD3A71;
}
.nonagon2-slice-8 .nonagon2-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon2-slice-8 .nonagon2-slice-triangle.active {
  background-color: #571B34
}
.nonagon2-slice-9 .nonagon2-slice-triangle {
  background-color: #e6e6e6;
}
.nonagon2-slice-9 .nonagon2-slice-triangle.active {
  background-color: #D687A9;
}


.nonagon2-slice-1 {
  transform: rotate(0deg);
}
.nonagon2-slice-2 {
  transform: rotate(40deg);
}
.nonagon2-slice-3 {
  transform: rotate(80deg);
}
.nonagon2-slice-4 {
  transform: rotate(120deg);
}
.nonagon2-slice-5 {
  transform: rotate(160deg);
}
.nonagon2-slice-6 {
  transform: rotate(200deg);
}
.nonagon2-slice-7 {
  transform: rotate(240deg);
}
.nonagon2-slice-8 {
  transform: rotate(280deg);
}
.nonagon2-slice-9 {
  transform: rotate(320deg);
}
.nonagon2-slice-triangle {
  transition: all 0.5s;
  position: absolute;
  width: 140px;
  height: 140px;
  clip-path: polygon(0% 0%, 50% 135%, 100% 0%);
  z-index: 999;
}

.nonagon2-slice-1 .nonagon2-slice-triangle:hover {
  background-color: #D64280;
}
.nonagon2-slice-2 .nonagon2-slice-triangle:hover {
  background-color: #573543;
}
.nonagon2-slice-3 .nonagon2-slice-triangle:hover {
  background-color: #FEA8CD;
}
.nonagon2-slice-4 .nonagon2-slice-triangle:hover {
  background-color: #AB6884;
}
.nonagon2-slice-5 .nonagon2-slice-triangle:hover {
  background-color: #F55698;
}
.nonagon2-slice-6 .nonagon2-slice-triangle:hover {
  background-color: #95003F;
}
.nonagon2-slice-7 .nonagon2-slice-triangle:hover {
  background-color: #BD3A71;
}
.nonagon2-slice-8 .nonagon2-slice-triangle:hover{
  background-color: #571B34
}
.nonagon2-slice-9 .nonagon2-slice-triangle:hover{
  background-color: #D687A9;
}



.cross-chart {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto 2rem;
  border-radius: 100%;
}
.cross-slice {
  position: absolute;
  /* padding-top: 1em; */
  /* left: 8em; */
  /* width: 7.25em;
  height: 19em;
  clip: rect(0, 20em, 10em, 0); */
  left: 120px;
  width: 100px;
  height: 380px;
  clip: rect(0, 380px, 140px, 0);
  text-align: center;
}
/* https://css-tricks.com/the-shapes-of-css/ */
/* .cross-slice:before {
  border-bottom: 35px solid red;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  content: "";
  height: 0;
  left: 40px;
  position: absolute;
  bottom: 160px;
  width: 0;
  z-index: 9999;
} */

.cross-slice-1 .cross-slice-triangle {
  background-color: #e6e6e6;
}
.cross-slice-1 .cross-slice-triangle.active {
  background-color: #E27E3F;
}
.cross-slice-2 .cross-slice-triangle {
  background-color: #e6e6e6;
}
.cross-slice-2 .cross-slice-triangle.active {
  background-color: #92CA51;
}
.cross-slice-3 .cross-slice-triangle {
  background-color: #e6e6e6;
}
.cross-slice-3 .cross-slice-triangle.active {
  background-color: #BA638A;
}
.cross-slice-4 .cross-slice-triangle {
  background-color: #e6e6e6;
}
.cross-slice-4 .cross-slice-triangle.active {
  background-color: #398EB8;
}


.cross-slice-1 {
  transform: rotate(0deg);
}
.cross-slice-2 {
  transform: rotate(90deg);
}
.cross-slice-3 {
  transform: rotate(180deg);
}
.cross-slice-4 {
  transform: rotate(270deg);
}

.cross-slice-triangle {
  transition: all 0.5s;
  position: absolute;
  width: 100px;
  height: 140px;
  /* clip-path: polygon(0% 0%, 50% 135%, 100% 0%); */
  z-index: 999;
}

.cross-slice-1 .cross-slice-triangle:hover {
  background-color: #E27E3F;
}
.cross-slice-2 .cross-slice-triangle:hover {
  background-color: #92CA51;
}
.cross-slice-3 .cross-slice-triangle:hover {
  background-color: #BA638A;
}
.cross-slice-4 .cross-slice-triangle:hover {
  background-color: #398EB8;
}
